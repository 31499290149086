var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { Camera } from 'react-camera-pro';
import styled from "styled-components";
import { FlashOnIcon } from "../../svg/FlashOnIcon";
import { FlashOffIcon } from "../../svg/FlashOffIcon";
import { RefreshIcon } from "../../svg/RefreshIcon";
import { CheckIcon } from "../../svg/CheckIcon";
import { BackgroundRemoveIcon } from "../../svg/BackgroundRemoveIcon";
import { getImageBlob } from "./form/FormDropZoneOrPhoto";
import LoadingBox from "./LoadingBox";
import { randomNumber } from "../../utils/NumberUtils";
var logger = require("js-logger").get("web.camera");
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    position: relative;\n    margin-bottom: 10px;\n"], ["\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    position: relative;\n    margin-bottom: 10px;\n"])));
var BottomButtons = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    left: 0;\n    bottom: 0;\n    display: flex;    \n    width: 100%;\n    position: absolute;\n    padding: 1rem 0;\n"], ["\n    left: 0;\n    bottom: 0;\n    display: flex;    \n    width: 100%;\n    position: absolute;\n    padding: 1rem 0;\n"])));
var BottomButtonsLeft = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex: 0.5\n"], ["\n    flex: 0.5\n"])));
var BottomButtonsCenter = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    flex: 1;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    gap: 2rem\n"], ["\n    flex: 1;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    gap: 2rem\n"])));
var BottomButtonsRight = styled(BottomButtonsLeft)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n"], ["\n"])));
var PhotoButton = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    background: rgba(255,255,255,0.5);\n    width: 8rem;\n    height: 8rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 10rem;\n    border-width: 0px;\n    & span {\n        display: flex;\n        background: rgba(255,255,255,1);\n        width: 6rem;\n        height: 6rem;\n        border-radius: 50%;\n    }\n"], ["\n    background: rgba(255,255,255,0.5);\n    width: 8rem;\n    height: 8rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 10rem;\n    border-width: 0px;\n    & span {\n        display: flex;\n        background: rgba(255,255,255,1);\n        width: 6rem;\n        height: 6rem;\n        border-radius: 50%;\n    }\n"])));
var PhotoPreviewPosition = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n"], ["\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n"])));
var LoadingBoxPosition = styled(PhotoPreviewPosition)(templateObject_8 || (templateObject_8 = __makeTemplateObject([""], [""])));
var IconButton = styled.button(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    width: 4rem;\n    height: 4rem;\n    border: 0;   \n    background: none;\n"], ["\n    width: 4rem;\n    height: 4rem;\n    border: 0;   \n    background: none;\n"])));
var FlashButton = styled(IconButton)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    \n"], ["\n    \n"])));
var RefreshButton = styled(IconButton)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    \n"], ["\n    \n"])));
var CheckButton = styled(IconButton)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    \n"], ["\n    \n"])));
var BackgroundButton = styled(IconButton)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["     \n    \n"], ["     \n    \n"])));
var PreviewBottomButtons = styled(BottomButtons)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    background: rgba(0,0,0, 0.6);\n"], ["\n    background: rgba(0,0,0, 0.6);\n"])));
export var WebCamera = React.memo(function (props) {
    var removeBackground = props.removeBackground, onApply = props.onApply;
    var cameraRef = React.useRef(null);
    var _a = React.useState(false), flashOn = _a[0], setFlashOn = _a[1];
    var _b = React.useState(null), photo = _b[0], setPhoto = _b[1];
    var _c = React.useState(false), torchSupported = _c[0], setTorchSupported = _c[1];
    var makePhoto = React.useCallback(function () {
        var photo = cameraRef.current.takePhoto();
        setPhoto(photo);
    }, [cameraRef.current]);
    React.useEffect(function () {
        if (photo && flashOn) {
            setFlashOn(cameraRef.current.toggleTorch());
        }
    }, [photo, flashOn, cameraRef.current]);
    return React.createElement(Container, null,
        React.createElement(Camera, { ref: cameraRef, aspectRation: "cover", facingMode: "environment", errorMessages: {
                noCameraAccessible: 'No camera device accessible. Please connect your camera or try a different browser.',
                permissionDenied: 'Permission denied. Please refresh and give camera permission.',
                switchCamera: 'It is not possible to switch camera to different one because there is only one video device accessible.',
                canvas: 'Canvas is not supported.',
            }, videoReadyCallback: function () {
                //logger.info('torchSupported callback', cameraRef.current.torchSupported);
                setTorchSupported(cameraRef.current.torchSupported);
            } }),
        React.createElement(BottomButtons, null,
            React.createElement(BottomButtonsLeft, null, torchSupported && React.createElement(FlashButton, { onClick: function () {
                    setFlashOn(cameraRef.current.toggleTorch());
                } }, flashOn ? React.createElement(FlashOnIcon, { iconColor: "#fff" }) : React.createElement(FlashOffIcon, { iconColor: "#fff" }))),
            React.createElement(BottomButtonsCenter, null,
                React.createElement(PhotoButton, { onClick: makePhoto },
                    React.createElement("span", null))),
            React.createElement(BottomButtonsRight, null)),
        photo && React.createElement(PhotoPreviewPosition, null,
            React.createElement(PhotoPreview, { removeBackground: removeBackground, imageBase64: photo, onClose: function () {
                    setPhoto(null);
                }, onApply: function (image) {
                    onApply(image);
                    setPhoto(null);
                } })));
});
var PhotoContainer = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    flex: 1;\n    background: #fff;\n    display: flex;\n    position: relative;\n"], ["\n    flex: 1;\n    background: #fff;\n    display: flex;\n    position: relative;\n"])));
var PhotoElement = styled.img(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    flex:1;\n    max-width: 100%;\n"], ["\n    flex:1;\n    max-width: 100%;\n"])));
export var PhotoPreview = React.memo(function (props) {
    var imageBase64 = props.imageBase64, onClose = props.onClose, removeBackground = props.removeBackground, onApply = props.onApply;
    var _a = React.useState(null), imageBlob = _a[0], setImageBlob = _a[1];
    var _b = React.useState(null), imageURL = _b[0], setImageURL = _b[1];
    var _c = React.useState(false), busy = _c[0], setBusy = _c[1];
    React.useEffect(function () {
        getImageBlob(imageBase64)
            .then(setImageBlob)
            .catch(function (err) {
            console.error(err);
        });
    }, [imageBase64]);
    React.useEffect(function () {
        if (imageBlob) {
            setImageURL(URL.createObjectURL(imageBlob));
        }
    }, [imageBlob]);
    var removeBkg = React.useCallback(function (image) {
        setBusy(true);
        removeBackground(image)
            .then(setImageBlob)
            .finally(function () {
            setBusy(false);
        });
    }, [imageBlob, busy]);
    return React.createElement(PhotoContainer, null,
        React.createElement(PhotoElement, { src: imageURL }),
        React.createElement(PreviewBottomButtons, null,
            React.createElement(BottomButtonsLeft, null),
            React.createElement(BottomButtonsCenter, null,
                React.createElement(RefreshButton, { onClick: onClose },
                    React.createElement(RefreshIcon, { iconColor: "#fff" })),
                React.createElement(CheckButton, { onClick: function () {
                        onApply(new File([imageBlob], "image_".concat(randomNumber(0, 10000), ".jpg")));
                    } },
                    React.createElement(CheckIcon, { iconColor: "#fff" })),
                React.createElement(BackgroundButton, { onClick: function () {
                        removeBkg(imageBlob);
                    } },
                    React.createElement(BackgroundRemoveIcon, { iconColor: "#fff" }))),
            React.createElement(BottomButtonsRight, null)),
        busy && React.createElement(LoadingBoxPosition, null,
            React.createElement(LoadingBox, null)));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
