import { HttpApiRequest } from "./network.service";
import { userStore } from "../store/user.store";
var ReportService = /** @class */ (function () {
    function ReportService() {
    }
    ReportService.prototype.getZReport = function (dayTimestamp) {
        var token = userStore.getState().token;
        return HttpApiRequest({
            method: "GET",
            params: {
                token: token,
                day: dayTimestamp,
            },
            url: "".concat(API_BASE_URL, "/report/z-report")
        }).promise
            .then(function (res) {
            return res.data;
        });
    };
    ReportService.prototype.getZReportOrders = function (dayTimestamp) {
        var token = userStore.getState().token;
        return HttpApiRequest({
            method: "GET",
            params: {
                token: token,
                day: dayTimestamp,
            },
            url: "".concat(API_BASE_URL, "/report/z-report-orders")
        }).promise
            .then(function (res) {
            return res.data;
        });
    };
    return ReportService;
}());
export { ReportService };
export var reportService = new ReportService();
