var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
var logger = require("js-logger").get("Touchable");
export var LEFT = "left";
export var RIGHT = "right";
export var UP = "up";
export var DOWN = " down";
var stopEventPropagation = function (e) {
    //console.log("STOP PROPAGATION");    
    e.stopPropagation();
    if (e.stopImmediatePropagation) {
        e.stopImmediatePropagation();
    }
    if (e.nativeEvent && e.nativeEvent.stopImmediatePropagation) {
        e.nativeEvent.stopImmediatePropagation();
    }
};
var getButtonClassNames = function (props) {
    var button = props.button, size = props.size, color = props.color, variant = props.variant;
    var classNames = [];
    if (button) {
        classNames.push("btn");
        var baseClassName = "btn";
        if (variant) {
            baseClassName = "btn-".concat(variant);
        }
        if (size) {
            classNames.push("btn-".concat(size));
        }
        if (color) {
            classNames.push("".concat(baseClassName, "-").concat(color));
        }
    }
    return classNames;
};
var touchSupported = false;
var Touchable = /** @class */ (function (_super) {
    __extends(Touchable, _super);
    function Touchable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.startX = 0;
        _this.startY = 0;
        _this.moveX = 0;
        _this.moveY = 0;
        _this.diffX = 0;
        _this.diffY = 0;
        _this.touchEvents = undefined;
        _this.node = undefined;
        _this.busy = false;
        _this.touchStart = function (e) {
            _this.touchEvents = true;
            touchSupported = true;
            console.log("TOUCH START");
            logger.info("Touch start");
            var onTouchStart = _this.props.onTouchStart;
            //e.preventDefault();
            _this.startX = e.touches[0].screenX;
            _this.startY = e.touches[0].screenY;
            _this.moveX = _this.startX;
            _this.moveY = _this.startY;
            if (onTouchStart) {
                onTouchStart(e);
            }
            document.addEventListener("touchmove", _this.touchMove, false);
            document.addEventListener("touchend", _this.touchEnd, false);
        };
        _this.touchMove = function (e) {
            logger.info("Touch move");
            var onTouchMove = _this.props.onTouchMove;
            _this.moveX = e.changedTouches[0].screenX;
            _this.moveY = e.changedTouches[0].screenY;
            _this.diffX = _this.startX - _this.moveX;
            _this.diffY = _this.startY - _this.moveY;
            if (onTouchMove) {
                onTouchMove(e, {
                    moveX: _this.moveX,
                    moveY: _this.moveY,
                    diffX: _this.diffX,
                    diffY: _this.diffY
                });
            }
        };
        _this.touchEnd = function (e) {
            logger.info("Touche End");
            var _a = _this.props, onTap = _a.onTap, onTouchEnd = _a.onTouchEnd, onSwipe = _a.onSwipe, disabled = _a.disabled;
            if (onTouchEnd) {
                onTouchEnd(e);
            }
            //console.log("TOUCH END", onTouch, onClick, disabled, e.target == this.node || this.node.contains(e.target))
            if ((e.target == _this.node || _this.node.contains(e.target)) && _this.diffX == 0 && _this.diffY == 0) {
                if (onTap && !disabled) {
                    e.preventDefault();
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    stopEventPropagation(e);
                    onTap(e);
                }
            }
            else {
                var directions = {};
                if (Math.abs(_this.diffX) > 20) {
                    directions["x"] = _this.diffX > 0 ? LEFT : RIGHT;
                }
                if (Math.abs(_this.diffY) > 20) {
                    directions["y"] = _this.diffY > 0 ? DOWN : UP;
                }
                if (onSwipe) {
                    onSwipe(directions);
                }
            }
            _this.startX = _this.startY = _this.moveX = _this.moveY = _this.diffX = _this.diffY = 0;
            document.removeEventListener("touchmove", _this.touchMove);
            document.removeEventListener("touchend", _this.touchEnd);
        };
        _this.onMouseDown = function (e) {
            //console.log("MOUSE DOWN", this.touchEvents, this.node);
            logger.info("Mouse down");
            if (touchSupported) {
                console.log("SKIP MOUSE DOWN EVENT");
                return;
            }
            //console.log("MOUSE DOWN", this.touchStarted, this.node);
            var onTouchStart = _this.props.onTouchStart;
            if (onTouchStart) {
                onTouchStart(e);
            }
            _this.startX = e.screenX;
            _this.startY = e.screenY;
            _this.moveX = _this.startX;
            _this.moveY = _this.startY;
            document.addEventListener("mousemove", _this.onMouseMove, false);
            document.addEventListener("mouseup", _this.onMouseUp, false);
        };
        _this.onMouseMove = function (e) {
            logger.info("Mouse move");
            if (_this.touchEvents) {
                console.log("SKIP MOUSE MOVE EVENT");
                return;
            }
            var onTouchMove = _this.props.onTouchMove;
            _this.moveX = e.screenX;
            _this.moveY = e.screenY;
            _this.diffX = _this.startX - _this.moveX;
            _this.diffY = _this.startY - _this.moveY;
            if (onTouchMove) {
                onTouchMove(e, {
                    diffX: _this.diffX,
                    diffY: _this.diffY
                });
            }
        };
        _this.onMouseUp = function (e) {
            logger.info("Mouse up");
            if (_this.touchEvents) {
                console.log("SKIP MOUSE UP EVENT");
                return;
            }
            //console.log("MOUSE UP", this.touchStarted, this.node);
            var _a = _this.props, onTap = _a.onTap, onTouchEnd = _a.onTouchEnd, onSwipe = _a.onSwipe, disabled = _a.disabled;
            if (onTouchEnd) {
                onTouchEnd(e);
            }
            //console.log(`DiffX: ${this.diffX}, DiffY: ${this.diffY}`, (e.target == this.node || this.node.contains(e.target)), e.target, this.node);
            //console.log("MOUSE UP")
            if ((e.target == _this.node || _this.node.contains(e.target)) && _this.diffX == 0 && _this.diffY == 0) {
                if (onTap && !disabled) {
                    stopEventPropagation(e);
                    onTap(e);
                }
            }
            else {
                var directions = {};
                if (Math.abs(_this.diffX) !== 0) {
                    directions["x"] = _this.diffX > 0 ? RIGHT : LEFT;
                }
                if (Math.abs(_this.diffY) !== 0) {
                    directions["y"] = _this.diffX > 0 ? DOWN : UP;
                }
                if (onSwipe) {
                    onSwipe(directions);
                }
            }
            _this.startX = _this.startY = _this.moveX = _this.moveY = _this.diffX = _this.diffY = 0;
            document.removeEventListener("mousemove", _this.onMouseMove);
            document.removeEventListener("mouseup", _this.onMouseUp);
        };
        _this.setNode = function (node) {
            var touchRef = _this.props.touchRef;
            if (node && !_this.node) {
                _this.node = node;
                if (touchRef) {
                    touchRef(node);
                }
            }
        };
        return _this;
    }
    Touchable.prototype.componentWillUnmount = function () {
        document.removeEventListener("touchmove", this.touchMove);
        document.removeEventListener("touchend", this.touchEnd);
        document.removeEventListener("mousemove", this.onMouseMove);
        document.removeEventListener("mouseup", this.onMouseUp);
        if (this.node) {
            this.node.removeEventListener("touchstart", this.touchStart);
            this.node.removeEventListener("mousedown", this.onMouseDown);
        }
    };
    Touchable.prototype.render = function () {
        //console.log("SCOPE", this);
        var _a = this.props, _b = _a.style, style = _b === void 0 ? {} : _b, children = _a.children, touchRef = _a.touchRef, href = _a.href, button = _a.button, disabled = _a.disabled, id = _a.id, onTap = _a.onTap, buttonType = _a.buttonType, size = _a.size, color = _a.color, variant = _a.variant;
        if (disabled) {
            style.opacity = "0.5";
        }
        var classNames = getButtonClassNames(this.props);
        if (this.props.className) {
            classNames.push(this.props.className);
        }
        var className = classNames.join(" ");
        if (button) {
            return React.createElement("button", { disabled: disabled, type: buttonType, className: "noselect ".concat(className), style: style, onTouchStart: this.touchStart, onMouseDown: this.onMouseDown, 
                //onClick={this.onClick.bind(this)}
                // onTouchEnd={this.touchEnd}  
                // onTouchMove={this.touchMove}                  
                // onTouchCancel={this.touchCancel}                    
                ref: this.setNode, id: id }, children);
        }
        if (href) {
            style.textDecoration = "none";
            return React.createElement("a", { href: href, className: "noselect ".concat(className), style: style, 
                //onTouchStart={this.touchStart}
                //onMouseDown={this.onMouseDown}
                onClick: onTap, 
                // onTouchEnd={this.touchEnd}  
                // onTouchMove={this.touchMove}                  
                // onTouchCancel={this.touchCancel}                    
                ref: this.setNode, id: id }, children);
        }
        return React.createElement("div", { className: "noselect ".concat(className), style: style, onTouchStart: this.touchStart, onMouseDown: this.onMouseDown, 
            //onClick={this.onClick.bind(this)}
            // onTouchEnd={this.touchEnd}  
            // onTouchMove={this.touchMove}                  
            // onTouchCancel={this.touchCancel}                    
            ref: this.setNode, id: id }, children);
    };
    return Touchable;
}(React.PureComponent));
export { Touchable };
