import { userStore } from "../store/user.store";
import { HttpApiRequest } from "./network.service";
import * as mimeExtJson from "../../assets/json/mime.json";
var getExtension = function (mime) {
    var values = Object.values(mimeExtJson);
    var idx = values.indexOf(mime.toLowerCase());
    if (idx < 0) {
        return null;
    }
    var extensions = Object.keys(mimeExtJson);
    return extensions[idx];
};
var ImageService = /** @class */ (function () {
    function ImageService() {
    }
    ImageService.prototype.removeBackground = function (image) {
        var token = userStore.getState().token;
        var form = new FormData();
        var ext = getExtension(image.type) || "png";
        form.append("image", new File([image], "image.".concat(ext)));
        return HttpApiRequest({
            method: "POST",
            data: form,
            responseType: "blob",
            params: { token: token },
            url: "".concat(IMAGE_APP_URL, "/remove-background")
        }, true)
            .promise
            .then(function (res) {
            return res.data;
        });
    };
    return ImageService;
}());
export var imageService = new ImageService();
