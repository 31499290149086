import * as React from "react";
import { useForm } from "react-hook-form";
import { Button } from "../common/Button";
import FormSelectInput from "../component/common/form/FormSelectInput";
import FormTextAreaInput from "../component/common/form/FormTextAreaInput";
export var AddPayment = React.memo(function (props) {
    var onSubmit = props.onSubmit, onCancel = props.onCancel;
    var _a = useForm({
        defaultValues: {
            paymentMethod: "cash"
        }
    }), control = _a.control, handleSubmit = _a.handleSubmit, formState = _a.formState;
    return React.createElement("div", { style: {
            padding: "2rem 0 0 0"
        } },
        React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", null,
                React.createElement(FormSelectInput, { id: "fpm", label: "Payment Method", control: control, name: "paymentMethod", rules: {
                        required: true
                    }, options: [
                        {
                            label: "Cash",
                            value: "cash"
                        },
                        {
                            label: "Credit Card",
                            value: "credit_card"
                        }
                    ] })),
            React.createElement("div", null,
                React.createElement(FormTextAreaInput, { control: control, label: "Notes", name: "notes" })),
            React.createElement("div", { style: {
                    margin: "2rem",
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "1rem"
                } },
                React.createElement(Button, { onClick: onCancel }, "Cancel"),
                React.createElement(Button, { color: "primary", type: "submit", disabled: !formState.isValid }, "Save"))));
});
