var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
var Report = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex: 1;\n    height: 100%;\n    display: flex;\n"], ["\n    flex: 1;\n    height: 100%;\n    display: flex;\n"])));
export var ReportPage = React.memo(function () {
    return React.createElement(Report, null,
        React.createElement(Outlet, null));
});
var templateObject_1;
