var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CButton, CImage, CListGroup, CListGroupItem, CModal, CModalBody } from "@coreui/react";
import { faCamera, faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useDropzone } from "react-dropzone";
import { useController } from "react-hook-form";
import 'react-html5-camera-photo/build/css/index.css';
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as _ from "underscore";
import { Button } from "../../../common/Button";
import { Touchable } from "../../../common/Touchable";
import { Box } from "../FlexItem";
import { Modal, ModalHeader, ModalHeaderTitle } from "../Modal";
var logger = require("js-logger").get("FormDropZone");
//import { removeBackground } from "@imgly/background-removal"
import axios from "axios";
import { imageService } from "../../../service/image.service";
import LoadingBox from "../LoadingBox";
import { WebCamera } from "../web.camera";
var ImagePreviewContainer = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 40px;\n    height: 40px;\n"], ["\n    width: 40px;\n    height: 40px;\n"])));
var isBase64String = function (str) {
    var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    return base64regex.test(str);
};
var b64toBlob = function (b64Data) {
    var buffer = Buffer.from(b64Data, "base64");
    var blob = new Blob([buffer], { type: "image/png" });
    return blob;
};
export var getImageBlob = function (image) { return __awaiter(void 0, void 0, void 0, function () {
    var img;
    return __generator(this, function (_a) {
        img = typeof image == "string" ? image : image instanceof File ? image : image.url;
        if (_.isString(img)) {
            if (isBase64String(img)) {
                return [2 /*return*/, b64toBlob(img)];
            }
            else {
                return [2 /*return*/, axios({
                        url: img.replace("https://static.ordish.app", "https://ord.ams3.digitaloceanspaces.com"),
                        responseType: "blob"
                    }).then(function (res) {
                        return res.data;
                    })];
            }
        }
        else {
            return [2 /*return*/, image];
        }
        return [2 /*return*/];
    });
}); };
var blobToBase64 = function (blob) {
    return new Promise(function (resolve, _) {
        var reader = new FileReader();
        reader.onloadend = function () { return resolve(reader.result); };
        reader.readAsDataURL(blob);
    });
};
var ImagePreview = React.memo(function (props) {
    var image = props.image, onChange = props.onChange;
    var _a = React.useState(null), imageURL = _a[0], setImageURL = _a[1];
    var _b = React.useState(false), showImage = _b[0], setShowImage = _b[1];
    var _c = React.useState(false), backgroundRemoved = _c[0], setBackgroundRemoved = _c[1];
    var _d = React.useState(false), busy = _d[0], setBusy = _d[1];
    var _e = React.useState(null), imageBlob = _e[0], setImageBlob = _e[1];
    var t = useTranslation().t;
    React.useEffect(function () {
        if (typeof image === "string") {
            setImageURL(image);
        }
        else if (image instanceof File) {
            var reader = new FileReader();
            reader.onload = function (e) {
                setImageURL(e.target.result);
            };
            reader.readAsDataURL(image);
        }
        else if (image.url !== undefined) {
            setImageURL(image.url);
        }
    }, [image]);
    React.useEffect(function () {
        if (!showImage && backgroundRemoved) {
            setBackgroundRemoved(false);
        }
    }, [showImage, backgroundRemoved]);
    return React.createElement(ImagePreviewContainer, null,
        imageURL && React.createElement(Touchable, { onTap: function () {
                setShowImage(true);
            } },
            React.createElement(CImage, { src: imageURL, width: 40 })),
        showImage && React.createElement(CModal, { visible: true },
            React.createElement(CModalBody, null,
                React.createElement(Box, { justify: "center", style: {
                        marginBottom: "10px"
                    } },
                    !busy && React.createElement(CImage, { src: imageURL, width: 200 }),
                    busy && React.createElement(LoadingBox, null)),
                React.createElement(Box, { justify: "center", display: "flex", flexDirection: "row", gap: "1rem" },
                    React.createElement(CButton, { onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var blob, url;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, getImageBlob(image)];
                                    case 1:
                                        blob = _a.sent();
                                        url = URL.createObjectURL(blob);
                                        setImageURL(url);
                                        setShowImage(false);
                                        return [2 /*return*/];
                                }
                            });
                        }); } }, t("close.text")),
                    !backgroundRemoved && React.createElement(CButton, { disabled: busy, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var blob, imgWithoutBkg, url, err_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        setBusy(true);
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 4, , 5]);
                                        return [4 /*yield*/, getImageBlob(image)];
                                    case 2:
                                        blob = _a.sent();
                                        return [4 /*yield*/, imageService.removeBackground(blob)];
                                    case 3:
                                        imgWithoutBkg = _a.sent();
                                        url = URL.createObjectURL(imgWithoutBkg);
                                        setImageURL(url);
                                        setBusy(false);
                                        setBackgroundRemoved(true);
                                        setImageBlob(imgWithoutBkg);
                                        return [3 /*break*/, 5];
                                    case 4:
                                        err_1 = _a.sent();
                                        setBusy(false);
                                        logger.error(err_1);
                                        return [3 /*break*/, 5];
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); } }, "Remove background"),
                    backgroundRemoved && imageBlob && React.createElement(CButton, { onClick: function () {
                            blobToBase64(imageBlob)
                                .then(function (imaBase64) {
                                onChange(imaBase64);
                            });
                            setShowImage(false);
                        } }, "Apply")))));
});
var getBytesFromBase64String = function (base64String) {
    return base64String.split(',')[1].length;
};
var bytesToKB = function (bytes) {
    return bytes / 1024;
};
export var bytesToMB = function (bytes) {
    return bytesToKB(bytes) / 1024;
};
export var KBToBytes = function (kb) {
    return kb * 1024;
};
export var MBToBytes = function (mb) {
    return mb * (1024 * 1024);
};
function formatBytes(bytes, decimals) {
    if (decimals === void 0) { decimals = 2; }
    if (!+bytes)
        return '0 Bytes';
    var k = 1024;
    var dm = decimals < 0 ? 0 : decimals;
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return "".concat(parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), " ").concat(sizes[i]);
}
var ImageSize = React.memo(function (props) {
    var image = props.image;
    var size = typeof image === "string" ? getBytesFromBase64String(image) : image.size;
    return React.createElement(React.Fragment, null, formatBytes(parseInt("".concat(size))));
});
var ImageInfo = React.memo(function (props) {
    var file = props.file, onChange = props.onChange;
    return React.createElement(Box, { align: "center" },
        React.createElement(Box, { flex: 1 },
            React.createElement(ImagePreview, { image: file, onChange: onChange })),
        React.createElement(ImageSize, { image: file }));
});
var ButtonsContainer = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 0.3rem 0;\n"], ["\n    margin: 0.3rem 0;\n"])));
export var FormDropZoneOrPhoto = React.memo(function (props) {
    var control = props.control, name = props.name, defaultValue = props.defaultValue, maxSize = props.maxSize;
    var _a = React.useState(false), showCamera = _a[0], setShowCamera = _a[1];
    var fileInputRef = React.useRef(null);
    var t = useTranslation().t;
    var toggleShowCamera = function () {
        setShowCamera(!showCamera);
    };
    var _b = useController({
        name: name,
        control: control,
        defaultValue: defaultValue,
        rules: {
            validate: function (value) {
                if (!maxSize) {
                    return undefined;
                }
                var size = typeof value === "string" ? getBytesFromBase64String(value) : value.size;
                return size > maxSize ? t("image.size.error.text", {
                    size: bytesToMB(maxSize)
                }) : undefined;
            }
        }
    }), _c = _b.field, onChange = _c.onChange, onBlur = _c.onBlur, _d = _c.value, value = _d === void 0 ? null : _d, ref = _c.ref, fieldState = _b.fieldState;
    var onDrop = React.useCallback(function (acceptedFiles) {
        // Do something with the files
        logger.info("Files", acceptedFiles);
        onChange(acceptedFiles[0]);
        //setAcceptedFiles(acceptedFiles);
    }, []);
    var _e = useDropzone({ onDrop: onDrop }), acceptedFiles = _e.acceptedFiles, getRootProps = _e.getRootProps, getInputProps = _e.getInputProps, isDragActive = _e.isDragActive;
    return React.createElement("section", { style: {
            width: "100%",
            marginTop: "5px"
        } },
        React.createElement(ButtonsContainer, { gap: "0.5rem" },
            React.createElement(Button, { color: "primary", onClick: toggleShowCamera },
                React.createElement(FontAwesomeIcon, { icon: faCamera })),
            React.createElement(Button, { type: "button", color: "primary", onClick: function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    fileInputRef.current.click();
                } },
                React.createElement(FontAwesomeIcon, { icon: faImage }))),
        value && React.createElement(CListGroup, null,
            React.createElement(CListGroupItem, null,
                React.createElement(ImageInfo, { file: value, onChange: onChange }))),
        !value && React.createElement(CListGroup, null,
            React.createElement(CListGroupItem, null, t("dish.nophoto.text"))),
        React.createElement("div", __assign({}, getRootProps({ className: 'dropzone' }), { ref: fileInputRef }),
            React.createElement("input", __assign({}, getInputProps())),
            React.createElement("p", null, t("files.drag.and.drop.text"))),
        fieldState.error && React.createElement("div", { style: { color: "red" } }, fieldState.error.message),
        showCamera && React.createElement(Modal, { visible: true, fullscreen: true },
            React.createElement(ModalHeader, { onClose: toggleShowCamera },
                React.createElement(ModalHeaderTitle, null, "Make Photo")),
            React.createElement(WebCamera, { removeBackground: imageService.removeBackground, onApply: function (image) {
                    onChange(image);
                    setShowCamera(false);
                } })));
});
var templateObject_1, templateObject_2;
